// React
import React, { useEffect, useState } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import s from 'src/styles';

// Utilities
import { useTouch } from 'hooks';

// Components
import { ReactComponent as SvgArrow } from 'src/images/arrow/arrow_arrow.svg';
import Loader from 'components/loader';

const ButtonComponent = ({
    onClick = () => {},
    label,
    theme = s.color('white'),
    type = 'primary',
    style = css``,
    arrow = false,
    vague = false,
    disabled = false,
    fullyDisabled = false,
    invertTheme = false,
    background = false,
    loading = false,
    feedback = null,
    className = '',
    submit = false,
}) => {
    // Touch
    const touch = process.env.NODE_ENV === 'development' ? true : useTouch();

    // Feedback and Loading
    const [localLoading, setLocalLoading] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    useEffect(() => {
        if (loading) {
            setLocalLoading(true);
        }
    }, [loading]);
    useEffect(() => {
        if (localLoading && loading === false && feedback) {
            setShowFeedback(true);
            setTimeout(() => {
                setLocalLoading(false);
                setShowFeedback(false);
            }, 1500);
        }
    }, [localLoading, loading]);

    return type === 'primary' ? (
        <PrimaryButton
            {...{
                type,
                theme,
                touch,
                disabled,
                invertTheme,
                isLoading: loading,
                showFeedback,
            }}
            onClick={event => {
                event.stopPropagation();
                if (!disabled && !fullyDisabled) {
                    onClick();
                }
            }}
            title={label}
            css={style}
            type={submit ? 'submit' : 'button'}
            className={className}>
            <LoaderWrapper
                {...{
                    isLoading: loading,
                    background: invertTheme ? theme.default() : theme.text(),
                }}>
                <Loader
                    {...{
                        triangle: invertTheme ? theme.text() : theme.default(),
                        background: invertTheme
                            ? theme.default()
                            : theme.text(),
                    }}
                />
            </LoaderWrapper>
            <Feedback
                {...{
                    showFeedback,
                    background: invertTheme ? theme.default() : theme.text(),
                }}>
                {feedback}
            </Feedback>
            <b>
                {label}
                {arrow && (
                    <Arrow {...{ theme }}>
                        <SvgArrow />
                    </Arrow>
                )}
            </b>
            <i>
                {label}
                {arrow && (
                    <Arrow {...{ theme }}>
                        <SvgArrow />
                    </Arrow>
                )}
            </i>
            <span />
        </PrimaryButton>
    ) : (
        <SecondaryButton
            {...{
                type,
                theme,
                vague,
                touch,
                disabled,
                invertTheme,
                background,
                isLoading: loading,
                showFeedback,
            }}
            onClick={() => {
                if (!disabled && !fullyDisabled) {
                    onClick();
                }
            }}
            title={label}
            css={style}
            type={submit ? 'submit' : 'button'}
            className={className}>
            <LoaderWrapper
                {...{
                    isLoading: loading,
                    background: background
                        ? background.default()
                        : invertTheme
                        ? theme.text()
                        : theme.default(),
                }}>
                <Loader
                    {...{
                        triangle: invertTheme ? theme.default() : theme.text(),
                        background: background
                            ? background.default()
                            : invertTheme
                            ? theme.text()
                            : theme.default(),
                    }}
                />
            </LoaderWrapper>
            <Feedback
                {...{
                    showFeedback,
                    background: background
                        ? background.default()
                        : invertTheme
                        ? theme.text()
                        : theme.default(),
                }}>
                {feedback}
            </Feedback>
            <b>
                {label}
                {arrow && (
                    <Arrow {...{ theme }}>
                        <SvgArrow />
                    </Arrow>
                )}
            </b>
            <span />
        </SecondaryButton>
    );
};

const HoverIn = keyframes`
    from {
        transform: translateX(0) rotate(30deg) ;
    }
    to {
        transform: translateX(90%) rotate(30deg);
    }
`;

const HoverOut = keyframes`
    from {
        transform: translateX(90%) rotate(30deg) ;
    }
    to {
        transform: translateX(0) rotate(30deg);
    }
`;

const Feedback = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.background};
    z-index: 10;
    transition: transform 400ms ${s.easings.smooth.out};
    transform: ${props =>
        props.showFeedback ? 'translateY(0)' : 'translateY(100%)'};
    ${s.fonts.style('h16')};
    white-space: nowrap;
`;

const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.background};
    z-index: 10;
    transition: transform 400ms ${s.easings.smooth.out};
    transform: ${props =>
        props.isLoading ? 'translateY(0)' : 'translateY(100%)'};
`;

const Arrow = styled.div`
    display: inline-flex;
    color: currentColor;
    position: relative;
    width: 1em;
    height: 1em;
    margin-left: 1em;
    transition: transform 400ms ${s.easings.smooth.out};

    svg {
        margin-top: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        width: 1.6em;
        height: 1.6em;
        path {
            fill: currentColor;
        }
    }
`;

const sharedButtonCss = props => css`
    border: 1px solid
        ${props.invertTheme ? props.theme.default() : props.theme.text()};
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props.disabled ? 'auto' : 'pointer'};

    ${s.responsive.property('padding', {
        0: '10px 30px',
        20: '15px 30px',
    })};

    b,
    i,
    ${Arrow} {
        transform: ${props.isLoading || props.showFeedback
            ? 'translateY(-50px)'
            : 'translateY(0)'};
    }

    /* Label */
    b,
    i {
        ${s.fonts.style('h16')};
        position: relative;
        z-index: 2;
        font-style: normal;
        white-space: nowrap;
        transition: transform 400ms ${s.easings.smooth.out};
    }

    i {
        position: absolute;
    }

    span {
        transform-origin: center center;
        transform: translateX(0) rotate(30deg);
        background: ${props.invertTheme
            ? props.theme.default()
            : props.theme.text()};
        position: absolute;
        left: -570px;
        width: 500px;
        height: 500px;
        z-index: 1;

        /* Hover out = default state */
        animation-name: ${HoverOut};
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: ${s.easings.smooth.out};
    }

    ${s.responsive.mq(30)} {
        ${!props.touch &&
        !props.disabled &&
        !props.fullyDisabled &&
        !props.isLoading &&
        css`
            &:hover {
                span {
                    /* Hover in = hover state */
                    animation-name: ${HoverIn};
                    animation-duration: 500ms;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    animation-timing-function: ${s.easings.smooth.out};
                }
            }
        `}
    }
`;

const PrimaryButton = styled.button`
    ${sharedButtonCss};

    color: ${props =>
        props.invertTheme ? props.theme.text() : props.theme.buttonText()};
    background: ${props =>
        props.invertTheme ? props.theme.default() : props.theme.text()};
    transition: background 10ms ${s.easings.smooth.out};

    b,
    i {
        transition: color 200ms 100ms ${s.easings.smooth.out},
            transform 400ms ${s.easings.smooth.out};
    }

    i {
        opacity: 0;
        color: ${props =>
            props.invertTheme ? props.theme.default() : props.theme.text()};
    }

    ${s.responsive.mq(30)} {
        ${props =>
            !props.touch &&
            !props.disabled &&
            !props.fullyDisabled &&
            !props.isLoading &&
            css`
                &:hover:not(:active) {
                    background: ${props.invertTheme
                        ? props.theme.text()
                        : props.theme.default()};
                    transition: background 130ms ${s.easings.smooth.out};
                    b {
                        opacity: 0;
                    }
                    i {
                        opacity: 1;
                        color: ${props.invertTheme
                            ? props.theme.text()
                            : props.theme.buttonText()};
                    }
                }
            `}
    }

    ${props =>
        props.fullyDisabled &&
        css`
            opacity: 0.4 !important;
            pointer-events: none;
        `}

    ${props =>
        props.disabled &&
        css`
            pointer-events: none;
        `}
`;

const SecondaryButton = styled.button`
    ${sharedButtonCss};

    color: ${props =>
        props.invertTheme ? props.theme.default() : props.theme.text()};

    background: ${props =>
        props.background ? props.background.default() : 'transparent'};

    transition: color 200ms 100ms ${s.easings.smooth.out},
        border-color 400ms ${s.easings.smooth.out};

    ${s.responsive.mq(30)} {
        ${props =>
            !props.touch &&
            !props.disabled &&
            !props.fullyDisabled &&
            !props.isLoading &&
            css`
                &:hover:not(:active) {
                    border-color: ${props.invertTheme
                        ? props.theme.default()
                        : props.theme.text()};
                    color: ${props.invertTheme
                        ? props.theme.text()
                        : props.theme.buttonText()};
                    transition: color 200ms ${s.easings.smooth.out},
                        border-color 400ms ${s.easings.smooth.out};
                }
            `}
    }

    ${props => props.vague && VagueCss}

    ${props =>
        props.fullyDisabled &&
        css`
            opacity: 0.4 !important;
            pointer-events: none;
        `}

        ${props =>
        props.disabled &&
        css`
            pointer-events: none;
        `}
`;

const VagueCss = props => css`
    border-color: ${props.invertTheme
        ? props.theme.alpha(0.7)
        : props.theme.textAlpha(0.7)};

    ${s.responsive.mq(30)} {
        ${!props.touch &&
        !props.disabled &&
        !props.fullyDisabled &&
        !props.isLoading &&
        css`
            &:hover {
                border-color: ${props.invertTheme
                    ? props.theme.default()
                    : props.theme.text()};
            }
        `}
    }
`;

export default ButtonComponent;
